<template>
<div>
    <div v-if="isAdmin" class="container">
        <div class="row btm-margin center_text">
            <b-button v-b-toggle.sidebar-backdrop style="width:84%;margin: 0% 8%">Welcome {{this.$root.store.username}} to CyTox Settings, Click here!</b-button>
        </div>
        <b-modal @ok="handleOk" v-model="modalShow">Are you certain you wish to delete {{this.table_to_delete_name}} table</b-modal>
        <b-sidebar id="sidebar-backdrop" title="Admin Options" :backdrop-variant="variant" backdrop shadow>
            <b-button v-b-toggle.collapse-1 class="m-1">DataBase View</b-button>
            <b-button v-b-toggle.collapse-2 class="m-1">Scanners Components Edit</b-button>
            <b-button v-b-toggle.collapse-3 class="m-1">Scanners Components Add</b-button>
            <b-button v-b-toggle.collapse-4 class="m-1">Add Table To DB</b-button>
            <b-button v-b-toggle.collapse-5 class="m-1">CyTox Users Table</b-button>
            <b-button v-b-toggle.collapse-6 class="m-1">System Status</b-button>
        </b-sidebar>
        <div class="row tp-margin">
            <b-collapse id="collapse-1">
                <div class="row">
                    <div class="col-md-3"></div>
                    <div class="col-md-6 tp-margin">
                        <json-view boxed :data="data" rootKey="CyToxDB DataBase view" :maxDepth="1" class="customize" />
                    </div>
                    <div class="col-md-3"></div>
                </div>
            </b-collapse>
            <b-collapse id="collapse-2">
                <div class="row">
                    <div class="col-md-3"></div>
                    <div class="col-md-6 tp-margin">
                        <b-form-select single v-model="selected" :options="options" @change="onChange()"></b-form-select>
                    </div>
                    <div class="col-md-3"></div>
                </div>
                <div class="row">
                    <div class="col-md-2"></div>
                    <div class="col-md-8 tp-margin">
                        <b-form-textarea id="textarea" v-model="text" rows="4" max-rows="6"></b-form-textarea>
                    </div>
                    <div class="col-md-2"></div>
                </div>
                <div class="row">
                    <div class="col-md-2"></div>
                    <div class="col-md-6"></div>
                    <div class="col-md-2 tp-margin">
                        <b-button @click='SaveDetails()'>Save Changes</b-button>
                    </div>
                    <div class="col-md-2"></div>
                </div>
            </b-collapse>
            <b-collapse id="collapse-3">
                <div class="row">
                    <div class="col-md-3"></div>
                    <div class="col-md-6 tp-margin">
                        <b-form-input id="scanner_add" placeholder="Enter Scanner Name" v-model="scanner_to_add_name" type="text"></b-form-input>
                    </div>
                    <div class="col-md-3"></div>
                </div>
                <div class="row">
                    <div class="col-md-2"></div>
                    <div class="col-md-8 tp-margin">
                        <b-form-textarea id="scanner_to_add_details" placeholder="Enter Scanner Details" v-model="scanner_to_add_details" rows="4" max-rows="6"></b-form-textarea>
                    </div>
                    <div class="col-md-2"></div>
                </div>
                <div class="row">
                    <div class="col-md-2"></div>
                    <div class="col-md-6"></div>
                    <div class="col-md-2 tp-margin">
                        <b-button @click='AddScannerComponent()'>Add Scanner Component</b-button>
                    </div>
                    <div class="col-md-2"></div>
                </div>
            </b-collapse>
            <b-collapse id="collapse-4">
                <div class="row btm-margin">
                    <div class="col-md-2"></div>
                    <div class="col-md-8">
                        <b-form-input id="table_add" placeholder="Enter Table Name" v-model="table_name_to_create" type="text"></b-form-input>
                    </div>
                    <div class="col-md-2"></div>
                </div>
                <div class="row">
                    <div class="col-md-3"></div>
                    <div class="col-md-6">
                        <div class="row form-group" v-for="(input,k) in inputs" :key="k">
                            <input type="text" class="form-control" v-model="input.column_name" :placeholder="'Column ' + [[ k+1 ]] + ' Name'">
                            <input type="text" class="form-control btm-margin" v-model="input.column_type" :placeholder="'Column ' + [[ k+1 ]]  + ' Type'">
                            <div style='margin-top: 1%;margin-left: 15%;'><i class="remove_field" @click="remove(k)" v-show="k || ( !k && inputs.length > 1)">Remove</i></div>
                            <div class="add_field" v-if="!value" @click="add()" v-show="k == inputs.length-1">Add fields</div>
                        </div>
                    </div>
                    <div class="col-md-3"></div>
                </div>
                <div class="row">
                    <div class="col-md-9"></div>
                    <div class="col-md-3">
                        <b-button @click='AddTableToDB'>Add Table</b-button>
                    </div>
                </div>
            </b-collapse>
            <b-collapse id="collapse-5">
                <div class="row">
                    <div class="col-md-3"></div>
                    <div class="col-md-6">
                        <ve-table :columns="columns" :table-data="user_data"></ve-table>
                    </div>
                    <div class="col-md-3"></div>
                </div>
            </b-collapse>

            <b-collapse id="collapse-6">
                <div class="row">
                    <div class="col-md-3"></div>
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-4">
                                <label for="from-datepicker">From date</label>
                                <b-form-datepicker id="from-datepicker" v-model="from_date_picker" class="mb-2"></b-form-datepicker>
                            </div>
                            <div class="col-md-4">
                                <label for="to-datepicker">To date</label>
                                <b-form-datepicker id="to-datepicker" v-model="to_date_picker" class="mb-2"></b-form-datepicker>
                            </div>
                            <div class='col-md-4'>
                                <b-button style="margin-top: 25%;" @click='get_status()'>Get Status</b-button>
                            </div>
                        </div>
                        <div class="row tp-margin">
                            <canvas id="myChart" width="'100vh'" height="'110vh'"></canvas>
                        </div>
                    </div>
                    <div class="col-md-3"></div>
                </div>
            </b-collapse>
        </div>
    </div>
    <div v-else>
        <h1>Need Admin Permission For This Page</h1>
    </div>
</div>
</template>

<script>
import VueSimpleAlert from "vue-simple-alert";
import Chart from 'chart.js/auto';
export default {

    data() {
        return {
            // labels:[],
            // data_polar:[],
            myChart:null,
            modalShow: false,
            variant: 'transparent',
            scanner_to_add_name: "",
            scanner_to_add_details: "",
            table_name_to_create: "",
            table_to_delete: null,
            table_to_delete_name: "",
            from_date_picker: "",
            to_date_picker: "",
            text: "",
            selected: null,
            data: {},
            columns: [{
                    field: "username",
                    key: "a",
                    title: "User Name",
                    align: "center",
                },
                {
                    field: "email",
                    key: "b",
                    title: "Email",
                    align: "center",
                },
                {
                    field: "permission",
                    key: "c",
                    title: "Permission",
                    align: "center",
                },
            ],
            scanners_comp: [],
            options: [{
                value: null,
                text: 'Please select a scanner'
            }, ],
            table_options: [{
                value: null,
                text: 'Please select a table to delete'
            }, ],
            inputs: [{
                column_name: '',
                column_type: ''
            }, ],
            user_data: [],
        };
    },
    computed: {
        isAdmin: function () {
            return this.$root.store.permission == 0;
        },
        isSelected: function () {
            return this.table_to_delete != null;
        },
    },
    methods: {
        ChangeTableModal() {
            for (var tableIndex = 0; tableIndex < this.table_options.length; tableIndex++) {
                if (this.table_options[tableIndex].value == this.table_to_delete) {
                    this.table_to_delete_name = this.table_options[tableIndex].text;
                }
            }
        },
        handleOk() {
            this.DeleteTable()
            //this.$router.go()
        },

        DeleteTable() {
            try {
                const content = {
                    username: this.$root.store.username,
                    tableName: this.table_to_delete_name
                };
                const json = JSON.stringify(content);
                this.axios.post(this.$store.state.server_domain + "TableDelete", json, this.$store.state.config).then((res) => {
                    if (res.data.data && res.data[0] != 'error') {
                        VueSimpleAlert.alert("the " + this.table_to_delete_name + " is deleted");
                    } else {
                        this.$root.toast("there is no results from splunk at the moment");
                    }
                }).catch((err) => {
                    console.log(err);
                    console.log("error happened");
                });
            } catch (err) {
                console.log("error happened");
            }
        },

        async get_status() {
            try {
                const content = {
                    username: this.$root.store.username,
                    from_date: this.from_date_picker,
                    to_date: this.to_date_picker
                };
                console.log(content);
                const json = JSON.stringify(content);
                this.axios.post(this.$store.state.server_domain + "GetStatus", json, this.$store.state.config).then((res) => {
                    if (res.data.data && res.data[0] != 'error') {
                        console.log(res.data.data);
                        const data_to_config = {
                        labels: res.data.data.labels,
                        datasets: [{
                            label: 'CyTox Status',
                            data: res.data.data.values,
                            backgroundColor: [
                            'rgb(255, 99, 132)',
                            'rgb(75, 192, 192)',
                            'rgb(255, 205, 86)',
                            ]
                        }]
                        };
                        const config = {
                        type: 'polarArea',
                        data: data_to_config,
                        options: {}
                        };
                        if(this.myChart != null)
                        {
                            this.myChart.destroy();
                        }
                        
                        const ctx = document.getElementById('myChart').getContext('2d');
                        this.myChart = new Chart(ctx, config);
                        console.log(this.myChart);
                        this.Chart.update();
                    } else {
                        this.$root.toast("there is no results from splunk at the moment");
                    }
                }).catch((err) => {
                    console.log(err);
                    console.log("error happened");
                });
            } catch (err) {
                console.log("error happened");
                console.log(err);
            }
        },

        add() {
            this.inputs.push({
                column_name: "",
                column_type: ""
            });
        },
        remove(index) {
            this.inputs.splice(index, 1);
        },
        onChange() {
            if (!this.selected) {
                this.text = "";
            } else {
                for (let i = 0; i < this.scanners_comp.length; i++) {
                    if (this.scanners_comp[i].id == this.selected) {
                        this.text = this.scanners_comp[i].details;
                        console.log(this.selected);
                    }
                }
            }
        },
        async SaveDetails() {
            if (this.selected === null) {
                this.text = "";
                this.$root.toast("select a scanner first", "");
            } else {
                try {
                    const content = {
                        username: this.$root.store.username,
                        scanner_id: this.selected,
                        scanner_details: this.text
                    };
                    const json = JSON.stringify(content);
                    this.axios.post(this.$store.state.server_domain + "ScannerComponentUpdate", json, this.$store.state.config).then((res) => {
                        if (res.data.data && res.data[0] != 'error') {
                            VueSimpleAlert.alert("Your Scanner Details Has Updated");
                            this.$router.go()
                        } else {
                            this.$root.toast("there is no results from splunk at the moment");
                        }
                    }).catch((err) => {
                        console.log(err);
                        console.log("error happened");
                    });
                } catch (err) {
                    console.log("error happened");
                }
            }
        },
        async AddScannerComponent() {
            if (this.scanner_to_add_name == "" || this.scanner_to_add_details == "") {
                VueSimpleAlert.alert("Fill the name and the details", "");
            } else {
                try {
                    const content = {
                        username: this.$root.store.username,
                        scanner_name: this.scanner_to_add_name,
                        scanner_details: this.scanner_to_add_details
                    };
                    const json = JSON.stringify(content);
                    this.axios.post(this.$store.state.server_domain + "ScannerComponentAdd", json, this.$store.state.config).then((res) => {
                        if (res.data.data && res.data[0] != 'error') {
                            VueSimpleAlert.alert("Scanner Inserted");
                            this.$router.go()
                        } else {
                            this.$root.toast("there is no results from splunk at the moment");
                        }
                    }).catch((err) => {
                        console.log(err);
                        console.log("error happened");
                    });
                } catch (err) {
                    console.log("error happened");
                }
            }
        },
        get_database_tree() {
            try {
                const content = {
                    username: this.$root.store.username,
                };
                const json = JSON.stringify(content);
                this.axios.post(this.$store.state.server_domain + "databaseTree", json, this.$store.state.config).then((res) => {
                    if (res.data.data && res.data[0] != 'error') {
                        this.data = res.data.data.CyToxDB;
                        var tableNames = Object.keys(this.data);
                        for (var tableIndex = 0; tableIndex < tableNames.length; tableIndex++) {
                            this.table_options.push({
                                value: tableIndex + 1,
                                text: tableNames[tableIndex]
                            });
                        }

                    } else {
                        this.$root.toast("there is no results from splunk at the moment");
                    }
                }).catch((err) => {
                    console.log(err);
                    console.log("error happened");
                });
            } catch (err) {
                console.log("error happened");
            }
        },
        async ScannersComponents() {
            try {

                this.axios.get(this.$store.state.server_domain + "ScannersComponents", this.$store.state.config).then((res) => {
                    if (res.data) {
                        this.scanners_comp = res.data.data;
                        for (let i = 0; i < this.scanners_comp.length; i++) {
                            this.options.push({
                                value: this.scanners_comp[i].id,
                                text: this.scanners_comp[i].name
                            })
                        }
                    } else {
                        this.$root.toast("ScannersComponents GET Failed", "", "failed");
                    }
                }).catch((err) => {
                    console.log("error happened");
                    console.log(err.response);
                });
            } catch (err) {
                console.log(err);
            }
        },

        async AddTableToDB() {

            if (this.inputs.length == 0 || this.table_name_to_create == "") {
                VueSimpleAlert.alert("Fill the name and the cols", "");
            } else {
                try {
                    const content = {
                        username: this.$root.store.username,
                        tableName: this.table_name_to_create,
                        columns: this.inputs
                    };
                    const json = JSON.stringify(content);
                    this.axios.post(this.$store.state.server_domain + "AddTableToDB", json, this.$store.state.config).then((res) => {
                        if (res.data.data && res.data[0] != 'error') {
                            VueSimpleAlert.alert("Table Created");
                            this.$router.go()
                        } else {
                            this.$root.toast("there is no results from splunk at the moment");
                        }
                    }).catch((err) => {
                        console.log(err);
                        console.log("error happened");
                    });
                } catch (err) {
                    console.log("error happened");
                }
            }
        },

        get_all_users() {
            try {
                const content = {
                    username: this.$root.store.username,

                };
                const json = JSON.stringify(content);
                this.axios.post(this.$store.state.server_domain + "GetAllUsers", json, this.$store.state.config).then((res) => {
                    if (res.data.data && res.data[0] != 'error') {
                        this.user_data = res.data.data;
                        // this.tableData = this.jobs_result;
                        // var arrayLength = this.jobs_result.length;
                        // for (var i = 0; i < arrayLength; i++) {
                        //     this.jobs_result[i].rowKey = 1000 + (i + 1);
                        // }

                    } else {
                        this.$root.toast("there is no results from seqScreen_res at the moment");
                    }
                }).catch((err) => {
                    console.log(err);
                    console.log("error happened");
                });
            } catch (err) {
                console.log("error happened");
            }
        },

    },
    beforeMount() {
        if (this.$root.store.username) {
            this.get_database_tree();
            this.ScannersComponents();
            this.get_all_users();
        } else {
            this.$root.toast("connect to Cy-Tox first");
        }
    },


};
</script>

<style>
.customize {
    --vjc-valueKey-color: green;
}

.btm-margin {
    margin-bottom: 3% !important;
}

.tp-margin {
    margin-top: 3%;
}

.quick-menu {
    top: auto !important;
    left: auto !important;
}

.value-key[data-v-c45632d2] {
    padding: 1% 30% !important;
}

.root-item[data-v-1c053202] {
    --vjc-key-color: #343a40 !important;
    --vjc-string-color: #a6a4a4 !important;

}

.center_text {
    margin: 10% 20%;
}

.collapse {
    width: 100% !important;
}

.add_field {
    margin: 10% -10%;
    color: white;
    background-color: #343a40;
    padding: 1%;
}

.remove_field {
    background-color: #343a40;
    color: white;
    padding: 10%;
}

.b-sidebar {
    width: 15% !important;
}

.b-sidebar-body button {
    width: 75% !important;
}

#collapse-4 input {
    width: 70% !important;
    margin: 0% 15%;
}
</style>
